import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { selectCurrentUser } from "../../store/user/user.selector";

const PrivateRoute = ({ setToastMessage, children }) => {
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  if (!currentUser) {

    setToastMessage("Please sign up to register for the academy.");     

    return (
      <Navigate
        to="/sign-up"
        state={{
          from: location
        }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
