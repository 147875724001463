import { createSelector } from "reselect";

export const selectAcademyUserReducer = (state) => state.academyUser;


export const selectAcademyRegisteredUsers = createSelector(
    [selectAcademyUserReducer],
    (academyUser) => academyUser?.registeredUsers
)

export const selectAcademyUserIsLoading = createSelector(
    [selectAcademyUserReducer],
    (academyUser) => academyUser?.isLoading
)

export const selectScrollAfterAcademyRegistration = createSelector(
    [selectAcademyUserReducer],
    (academyUser) => academyUser.scrollToSection
)