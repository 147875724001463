import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAcademyUserDocument } from "../../utils/firebase/firebase.utils";

export const getAcademyUsers = createAsyncThunk(
  "academyUser/getAcademyUsers",
  async (_, thunkAPI) => {
    try {
      const response = await getAcademyUserDocument("academy-registrations");
    
      return response;
    } catch (error) {
      console.log('Error in getAcademyUsers thunk:', error);
      
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const ACADEMY_INITIAL_STATE = {
  registeredUsers: [],
  isLoading: false,
  error: null,
  scrollToSection: null
};

export const academyUserSlice = createSlice({
  name: "academyUser",
  initialState: ACADEMY_INITIAL_STATE,
  reducers: {
    setScrollToSection(state, action) {
      state.scrollToSection = action.payload;
    },
    clearScrollToSection(state) {
      state.scrollToSection = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAcademyUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAcademyUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registeredUsers = action.payload;
        state.error = null;
      })
      .addCase(getAcademyUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setScrollToSection, clearScrollToSection } = academyUserSlice.actions;

export const academyUserReducer = academyUserSlice.reducer;
