import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import BlogPreview from "../../components/blog-preview/blog-preview.component"
import BookPreview from "../../components/book-preview/book-preview.component"
import EventPreview from "../../components/event-preview/event-preview.component"
import LandingPage from "../../components/landing-page/landing-page.component"
import { selectScrollAfterAcademyRegistration } from "../../store/academy-user/academy-user.selector"
import { clearScrollToSection } from "../../store/academy-user/academy-user.slice"


const Home = () => {
    const dispatch = useDispatch()
    const scrollToSection = useSelector(selectScrollAfterAcademyRegistration)

    const bookPreviewRef = useRef(null)

    useEffect(() => {
        if (scrollToSection === 'book-preview' && bookPreviewRef.current) {
            bookPreviewRef.current.scrollIntoView({behavior: 'smooth'});
            dispatch(clearScrollToSection())
        }
    }, [scrollToSection])

    return (
        <>
            <LandingPage />
            <div ref={bookPreviewRef}>
                <BookPreview />
            </div>
            <BlogPreview />
            <EventPreview />
        </>
    )
}

export default Home