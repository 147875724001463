import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAcademyUsers } from "../../store/academy-user/academy-user.slice";
import "./admin.styles.scss";
import {
  selectAcademyRegisteredUsers,
  selectAcademyUserIsLoading,
} from "../../store/academy-user/academy-user.selector";
import Spinner from "../../components/spinner/spinner.component";

const Admin = () => {
  const dispatch = useDispatch();
  const registeredUsers = useSelector(selectAcademyRegisteredUsers);
  console.log("The Registered Users>>>", registeredUsers);

  const isLoading = useSelector(selectAcademyUserIsLoading);

  useEffect(() => {
    dispatch(getAcademyUsers());
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="admin-page">
            <h2>Registered Users</h2>

            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Marital Status</th>
                    <th>Age Bracket</th>
                    <th>Church/Ministry</th>
                    <th>Business</th>
                    <th>Mentors</th>
                    <th>Courses</th>
                    <th>Expectations</th>
                    <th>Intentions</th>
                    <th>Registration Time</th>
                  </tr>
                </thead>
                <tbody>
                  {registeredUsers.map((registration, index) => (
                    <tr key={index}>
                      <td>{registration.displayName || "N/A"}</td>
                      <td>{registration.email || "N/A"}</td>
                      <td>{`+234${registration.phoneNumber}` || "N/A"}</td>
                      <td>{registration.address || "N/A"}</td>
                      <td>{registration.maritalStatus || "N/A"}</td>
                      <td>{registration.ageBracket || "N/A"}</td>
                      <td>{registration.nameOfChurch || "N/A"}</td>
                      <td>{registration.nameOfBusiness || "N/A"}</td>
                      <td>{registration.mentors || "N/A"}</td>
                      <td>
                        {Array.isArray(registration.coursesOfInterest)
                          ? registration.coursesOfInterest.join(", ")
                          : "N/A"}
                      </td>
                      <td>{registration.expectations || "N/A"}</td>
                      <td>{registration.intentions || "N/A"}</td>
                      <td>
                        {registration.createdAt
                          ? new Date(
                              registration.createdAt.seconds * 1000
                            ).toLocaleString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
